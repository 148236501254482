import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-24291b2c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_el_skeleton, {
    rows: 10,
    loading: _ctx.isLoading,
    throttle: 0
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      innerHTML: _ctx.contentHtml
    }, null, 8, _hoisted_2)]),
    _: 1
  }, 8, ["loading"])]);
}