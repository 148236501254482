import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d03fe620"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "back-news-hint"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_not_found = _resolveComponent("not-found");
  return _openBlock(), _createBlock(_component_not_found, {
    class: "illegal-id"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createTextVNode(" 返回"), _createElementVNode("span", {
      class: "back-news",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.router.push({
        path: '/news'
      }))
    }, "资讯列表"), _createTextVNode("页面 ")])]),
    _: 1
  });
}