import { defineComponent } from 'vue';
import ProductItem from './ProductItem.vue';
export default defineComponent({
  name: 'NewsRecommendProductList',
  components: {
    ProductItem
  },
  props: {
    headerVisible: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 推荐商品的飘红关键词
    keywords: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    // 组件的别名 (可为埋点上报使用的 区分在哪个位置引用的此组件上报的数据)
    componentAlias: {
      type: String,
      default: 'product-list'
    },
    skeletonNum: {
      type: Number,
      default: 3
    },
    noCard: {
      type: Boolean,
      default: false
    },
    // 埋点的一些信息
    logInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    return {};
  }
});