import {ElInput, ElSelect, ElCascader, ComponentSize, FormItemRule} from 'element-plus';
import {VNode, StyleValue} from 'vue';
import {IOptionItem} from '@/base/api/interface/global';

export type ITFormItemConfig =
    | IUndefinedTypeConfig
    | ICustomConfig
    | ITextInputConfig
    | ITextareaConfig
    | ISelectConfig
    | ICascaderConfig;

interface ITFormItemBaseConfig {
    // FormItem Props
    label: string;
    prop: string;
    key?: number | string; // 默认 key 值为 prop，如果是动态增减表单项，需要手动指定 key
    disableVModel?: boolean;
    labelWidth?: string | number;
    required?: boolean;
    rules?: FormItemRule | FormItemRule[];
    error?: string;
    showMessage?: boolean;
    inlineMessage?: boolean;
    size?: ComponentSize;
    // Render Props
    span?: number;
    colStyle?: StyleValue;
    placeholder?: string;
    clearable?: boolean;
}

export interface IUndefinedTypeConfig extends ITFormItemBaseConfig {
    type?: undefined;
    componentProps?: InstanceType<typeof ElInput>['$props'];
}

export interface ICustomConfig extends ITFormItemBaseConfig {
    type: 'custom';
    render: () => VNode;
    componentProps?: never;
}

export interface ITextInputConfig extends ITFormItemBaseConfig {
    type: 'input';
    componentProps?: InstanceType<typeof ElInput>['$props'];
}

export interface ITextareaConfig extends ITFormItemBaseConfig {
    type: 'textarea';
    componentProps: {
        rows: number; // 文本域的默认高度
    } & InstanceType<typeof ElInput>['$props'];
}

export interface ISelectConfig extends ITFormItemBaseConfig {
    type: 'select';
    componentProps: {
        options: IOptionItem[] | (() => Promise<IOptionItem[]>); // 选择器选项配置（可以传入一个方法，返回 Promise 对象来从远程拉取选项）
    } & InstanceType<typeof ElSelect>['$props'];
}

export interface ICascaderConfig extends ITFormItemBaseConfig {
    type: 'cascader';
    cascaderOptions?: any[];
    areaProps?: {
        label: string;
        value: string;
    };
    componentProps?: InstanceType<typeof ElCascader>['$props'];
}

export enum SHOW_MESSAGE_MODE {
    normal = 'normal',
    toast = 'toast',
}
