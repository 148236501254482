import { defineComponent } from 'vue';
import { formateIssuetime } from '@/base/utils/time';
export default defineComponent({
  name: 'DetailHeader',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    // 返回方式需求变化，先注释掉
    // 关闭新闻详情页
    // const closeNewsDetail = () => {
    //     window.opener = null;
    //     window.open('', '_self');
    //     window.close();
    // }
    return {
      // closeNewsDetail,
      formateIssuetime
    };
  }
});