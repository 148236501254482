/**
 * @file 缓存数据，方便回退后保留现场
 * @author chengong03(chengong03@baidu.com)
 * @date 2018-12-04
 */

import {
    setCacheData as set,
    getCacheData as get,
    delCacheData as del,
} from '@baidu/b2b-util';

/**
 * 数据缓存的 key 前缀
 *
 * @const
 * @type {string}
 */
const CACHE_DATA = 'V_DATA_PC_';

// localStorage 在低版本 iOS Safari 的隐私模式下无法使用，手机Safari浏览器中具体表现是
// - localStorage对象仍然存在
// - 但是 setItem 会报异常：QuotaExceededError
// - getItem 和 removeItem 直接忽略

/**
 * 缓存列表数据
 *
 * @public
 * @param {string} key key
 * @param {Object} cacheData 数据
 * @param {boolean=} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function setCacheData(key: string, cacheData, useLocal: boolean = false) {
    return set(key, cacheData, useLocal, CACHE_DATA);
}

/**
 * 取出列表数据
 *
 * @public
 * @param {string} key key
 * @param {boolean=} useLocal 是否永久缓存
 * @return {Object}
 */
export function getCacheData(key: string, useLocal?: boolean) {
    return get(key, useLocal, CACHE_DATA);
}

/**
 * 清理 data 和 scrollTop 缓存
 *
 * @public
 * @param {string} key key
 * @param {boolean=} useLocal 是否永久缓存
 * @return {boolean} 是否设置成功
 */
export function delCacheData(key: string, useLocal?: boolean) {
    return del(key, useLocal, CACHE_DATA);
}
