import { useHead } from '@vueuse/head';
import { computed, defineComponent, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import DetailContent from '../components/DetailContent.vue';
import DetailFooter from '../components/DetailFooter.vue';
import DetailHeader from '../components/DetailHeader.vue';
import IllegalId from '../components/IllegalId.vue';
import NewsRecommendProductList from '../components/Recommend/ProductList.vue';
import UpdateTime from '@/components/UpdateTime/index.vue';
import { NewsMutationEnum } from '@/store/news';
import { SeoListMutationEnum } from '@/store/seolist';
import TLayout from '@/components/Layout/Layout.vue';
import ClientOnly from '@/components/ClientOnly/index.vue';
import TNavNews from '@/views/News/components/NavNews.vue';
import SettleForm from '@/views/components/SettleForm/Index.vue';
// import {TRIAL_TYPE_ENUM} from '@/views/components/Freetrial/types';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getSeoConfig, seoBaseConfig } from '@/base/config/seo';
import { getNewDetail } from '@/base/api/news';
import { getSeoList } from '@/base/api/seolist';
import { useStore } from '@/store';
import { formateIssuetime } from '@/base/utils/time';
import { handleUrl } from '@/base/utils/url';
import { scLog } from '@/base/log/sclog';
import { IS_NODE, isMobileUa } from '@/base/utils';
import { useSafeSSRContext } from '@/base/hooks/useSafeSSRContext';
import { SHOW_MESSAGE_MODE } from '@/components/TForm/types';
import { NEWS_DETAIL_PATH_ENUM } from '@/base/api/interface/news';
const AD_URL = 'https://b2b.baidu.com/superior?t=superior&q=%E5%87%BA%E5%8F%A3&fid=0%2C1662694380802&pi=b2b.s....2276302111356024';
const TIPS_LIST = ['有额外要求？告诉我替您智能匹配', '填写更多，为您更精准匹配商品', '留下更多信息，我帮您筛选供应商', '有更多更细的要求？我为您匹配', '还有参数要求？我帮您寻找合适商品', '一个词说不清？来这里可以找到更多'];
const AIBOT_URL = 'https://b2b.baidu.com/m/aibot?fid=671154177';
export default defineComponent({
  name: 'NewsVue',
  components: {
    TLayout,
    DetailContent,
    DetailFooter,
    DetailHeader,
    IllegalId,
    TNavNews,
    SettleForm,
    UpdateTime,
    NewsRecommendProductList,
    ClientOnly
  },
  setup() {
    var _route$params, _route$meta;
    const isMobile = isMobileUa();
    const route = useRoute();
    const store = useStore();
    const ssrContext = useSafeSSRContext();
    // 资讯详情接口入参
    let requestDetailParams = {
      id: ((_route$params = route.params) === null || _route$params === void 0 ? void 0 : _route$params.id) || ''
    };
    // 入参 path: 'contents' 用于在 路由path 为 '/content/detail/:id' 时，获取内容, 此时是必传
    // 入参 path: 'news' 或没有 path 参数时 用于在 路由path 为 '/news/detail/:id' 时，获取新闻
    if (((_route$meta = route.meta) === null || _route$meta === void 0 ? void 0 : _route$meta.pageName) === 'contents-detail') {
      requestDetailParams.path = NEWS_DETAIL_PATH_ENUM.contents;
    }
    const {
      state
    } = useStoreRequest({
      space: 'news',
      mutationName: NewsMutationEnum.SET_NEWS_DETAIL_DATA,
      stateKey: 'newsDetailData'
    }, params => getNewDetail(params, IS_NODE ? {
      headers: {
        cookie: ssrContext === null || ssrContext === void 0 ? void 0 : ssrContext.cookie
      }
    } : undefined), requestDetailParams);
    const data = computed(() => state.value);
    const loading = computed(() => data.value.isLoading);
    const recommendProduct = computed(() => {
      var _data$value$data;
      return (_data$value$data = data.value.data) === null || _data$value$data === void 0 ? void 0 : _data$value$data.product_recommend;
    });
    // productRecommendQuery 用于推荐商品的相关点击事件发生时埋点上报使用，以用来区分点击的推荐商品的推荐策略
    const productRecommendQuery = computed(() => {
      var _data$value$data2;
      return ((_data$value$data2 = data.value.data) === null || _data$value$data2 === void 0 ? void 0 : _data$value$data2.product_recommend_query) || '';
    });
    // pv 上报
    const pvSendCount = ref(0);
    // NOTICE: 这里一定要监听 全局 store 的数据，否则 ssr 就不会渲染内容，问题见：
    // https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/3kyfr_8TGU/HDeySYhuyv/9VjMbIUoK5ExL_
    watch(() => store.state.news.newsDetailData, newVal => {
      // pvSendCount < 1 为了防止多次上报
      if (pvSendCount.value < 1 && newVal) {
        // product_recommend_query: 本页面推荐商品的推荐策略
        const ext = newVal !== null && newVal !== void 0 && newVal.product_recommend_query ? {
          product_recommend_query: newVal.product_recommend_query
        } : {};
        scLog('pv', {
          ext: JSON.stringify(ext)
        });
        // ai 生成的文章时 再上报一次 pv
        if (typeof (newVal === null || newVal === void 0 ? void 0 : newVal.general_type) === 'number') {
          // 神策打点
          scLog('pv', {
            generalType: newVal.general_type,
            page: 'newsdetail-ai',
            ext: JSON.stringify(ext)
          });
        }
        pvSendCount.value += 1;
      }
    }, {
      immediate: true,
      deep: true
    });
    // 推荐商品的搜索商品词, 用来给询盘表单的商品名称自动填充使用
    const inquiryQuery = computed(() => {
      var _data$value$data3;
      return (((_data$value$data3 = data.value.data) === null || _data$value$data3 === void 0 ? void 0 : _data$value$data3.ner_query) || '').substring(0, 100);
    });
    // quesetion 参数，用于 aibot智能问答
    const aibotQuestion = computed(() => {
      var _state$value$data;
      return inquiryQuery.value || ((_state$value$data = state.value.data) === null || _state$value$data === void 0 ? void 0 : _state$value$data.title);
    });
    // 生成aibot url
    const aibotUrl = computed(() => AIBOT_URL + (aibotQuestion.value ? `&question=${aibotQuestion.value}` : ''));
    const header = computed(() => {
      var _state$value$data2;
      return {
        title: ((_state$value$data2 = state.value.data) === null || _state$value$data2 === void 0 ? void 0 : _state$value$data2.title) || '',
        ...state.value.data
      };
    });
    const metaKeyword = computed(() => {
      var _state$value$data3;
      const tags = (_state$value$data3 = state.value.data) === null || _state$value$data3 === void 0 ? void 0 : _state$value$data3.tag;
      return tags;
    });
    const metaDescription = computed(() => {
      var _data$value$data4, _data$value$data5;
      return ((_data$value$data4 = data.value.data) === null || _data$value$data4 === void 0 ? void 0 : _data$value$data4.description) || ((_data$value$data5 = data.value.data) === null || _data$value$data5 === void 0 || (_data$value$data5 = _data$value$data5.digest) === null || _data$value$data5 === void 0 ? void 0 : _data$value$data5.slice(0, 120));
    });
    useHead(computed(() => {
      var _state$value$data4;
      const seoConfig = getSeoConfig({
        title: `${header.value.title || seoBaseConfig.news.title}`,
        datePublished: `${formateIssuetime(header.value.issue_at)} 00:00:00`,
        keywords: metaKeyword.value,
        description: metaDescription.value
      }) || {};
      const url = 'https://chuhaiyi.baidu.com/news/detail/';
      const id = ((_state$value$data4 = state.value.data) === null || _state$value$data4 === void 0 ? void 0 : _state$value$data4.id) || '';
      const linkCanonical = {
        link: [{
          rel: 'canonical',
          href: `${url}${id}`
        }]
      };
      // 增加 <link rel="canonical" href=""> 标签
      return Object.assign({}, seoConfig, linkCanonical);
    }));
    const isSpider = computed(() => {
      return store.state.spiderInfo.isSpider;
    });
    // 获取下方新闻资讯卡片的内容
    const {
      state: seoListState
    } = useStoreRequest({
      space: 'seolist',
      mutationName: SeoListMutationEnum.SET_SEO_LIST_DATA,
      stateKey: 'seoListData'
    }, getSeoList, {
      term: 'all'
    });
    const seoList = computed(() => {
      var _seoListState$value;
      return ((_seoListState$value = seoListState.value) === null || _seoListState$value === void 0 || (_seoListState$value = _seoListState$value.data) === null || _seoListState$value === void 0 ? void 0 : _seoListState$value.all) || [];
    });
    const navCardList = computed(() => ({
      seolist: seoList.value
    }));
    function handleAdClick() {
      scLog('click', {
        elem: 'news-detail-ad'
      });
    }
    return {
      loading,
      data,
      recommendProduct,
      productRecommendQuery,
      AD_URL,
      header,
      isSpider,
      navCardList,
      // TRIAL_TYPE_ENUM,
      SHOW_MESSAGE_MODE,
      inquiryQuery,
      handleUrl,
      handleAdClick,
      isMobile,
      TIPS_LIST,
      aibotUrl
    };
  }
});