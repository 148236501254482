import { computed, defineComponent, reactive } from 'vue';
import { setNewsLike } from '@/base/api/news';
import { IS_NODE } from '@/base/utils';
import TButton from '@/components/TButton/index.vue';
export default defineComponent({
  name: 'DetailFooter',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    TButton
  },
  setup(props) {
    let alreadyLike = computed(() => {
      var _props$data;
      return IS_NODE ? false : !!localStorage.getItem(`NEWS_${(_props$data = props.data) === null || _props$data === void 0 ? void 0 : _props$data.id}`);
    });
    const aboutLike = reactive({
      isClick: false,
      isLike: false
    });
    async function setLikeClick(id) {
      if (aboutLike.isLike || alreadyLike.value) {
        return;
      }
      // 文章的点赞信息存储在浏览的器的本地缓存中
      const setLikeRes = await setNewsLike({
        id
      });
      if (setLikeRes.errno === 0) {
        localStorage.setItem(`NEWS_${id}`, 'like');
        aboutLike.isLike = true;
        aboutLike.isClick = true;
      }
    }
    const handleToNewsDetail = id => {
      if (!id) {
        return;
      }
      window.open(`/news/detail/${id}`, '_blank');
    };
    return {
      aboutLike,
      alreadyLike,
      setLikeClick,
      handleToNewsDetail
    };
  }
});