/**
 * @file 引入百度识瞳反爬脚本地址
 * @author maominyang@baidu.com
 * @date 2019.05.07
 */
import {loadScript} from '@baidu/b2b-util';
import {getCacheData, setCacheData} from './b2b-cache';
import {IS_NODE} from './index';

const isServer = IS_NODE;
/**
 * 百度识瞳反爬脚本地址
 *
 * @const
 * @type {string}
 */
const URL = 'https://dlswbr.baidu.com/heicha/mw/abclite-2026-s.js';

/**
 * 反爬虫结果返回超时时间
 *
 * @const
 * @type {number}
 */
const TIMEOUT = 5000;

/**
 * 反爬虫缓存键值
 *
 * @const
 * @type {String}
 */
const CACHE_KEY = 'ANTI_SPIDER';

/**
 * 反爬虫结果键名
 *
 * @const
 * @type {String}
 */
const RES_KEY = '__abbaidu_20181211_';

// 0:未被初始化 1:初始化中 2:已初始化 3:初始化失败
let initStatus = 0;

// resp resolve handle
let resolveResp = null;

// 识瞳的结果
const resp = new Promise(resolve => {
    // @ts-ignore
    resolveResp = resolve;
});

/**
 * 载入脚本的回调函数
 *
 * @param  {Error} err  载入脚本时出现的错误
 */
const callback = err => {
    initStatus = err ? 3 : 2;

    if (err) {
        // @ts-ignore
        resolveResp({[RES_KEY]: ''});
    }
    else {
        setTimeout(() => {
            // @ts-ignore
            resolveResp({[RES_KEY]: ''});
        }, TIMEOUT);
    }
};

export default {
    /**
     * 初始化
     */
    init() {
        if (initStatus === 0 && !isServer) {
            initStatus = 1;
            // 从缓存中获取结果直接resolve
            const respFromCache = getCacheData(CACHE_KEY);
            if (respFromCache) {
                // @ts-ignore
                resolveResp({[RES_KEY]: respFromCache});
            }
            // 全局注册接收识瞳结果的函数
            // @ts-ignore
            window.__abbaidu_2026_cb = data => {
                // 缓存或更新data
                setCacheData(CACHE_KEY, data);
                // @ts-ignore
                resolveResp({[RES_KEY]: data});
            };
            // 异步载入js
            loadScript(URL, callback);
        }
    },

    /**
     * 获取识别结果
     *
     * @return {string} 识别结果
     */
    getResp() {
        return resp;
    },
};
