import {ref} from 'vue';
import {ElMessage} from 'element-plus';

// utils
import {scLog} from '@/base/log/sclog';
import antiSpider from '@/base/utils/antiSpider';
import {postCaptchaApi, postCheckCaptchaApi} from '@/base/api/settle';

// 初始化反爬虫系统
antiSpider.init();

export function usePhoneCaptcha(settleFormRef, form, isSubmitValidate) {
    const NET_ERROR_TIP = '网络错误，请重试';

    // 获取验证码状态 0:未获取或获取失败, 1:获取中, 2:已获取
    const verifyStatus = ref<0 | 1 | 2>(0);
    // 验证码重新发送计时
    const verifyRetryTime = ref<number>(0);
    // 验证码按钮点击次数
    const logCaptchaTryTimes = ref<number>(0);

    const verifyInterval = ref<ReturnType<typeof setInterval>>();

    /**
     * 点击获取验证码
     */
    function handleVerify() {
        // 埋点 from 标识是什么表单获取验证码
        scLog('click', {
            elem: 'b2b-settle-captcha-click',
            from: 'b2b-settle-form',
        });
        // 统计验证码按钮点击次数
        logCaptchaTryTimes.value++;

        if (verifyStatus.value) {
            return;
        }

        settleFormRef.value?.validateField('phone', valid => {
            if (!valid) {
                // 电话校验不通过
                return;
            }

            verifyStatus.value = 1;
            antiSpider.getResp()
                .then(async resp => {
                    try {
                        const res = await postCaptchaApi({
                            // @ts-ignore
                            ...resp,
                            phone_num: form.phone,
                            // 把页面信息带上，方便 BI 统计成本归属
                            page: 'chuhaiyi-news-detail',
                        });

                        const {status, msg} = res;

                        if (!status) {
                            ElMessage.success(msg);
                            startVerifyTimer();
                        }
                        else {
                            ElMessage.error(msg);
                            verifyStatus.value = 0;
                        }
                    }
                    catch (err) {
                        ElMessage.error(NET_ERROR_TIP);
                        verifyStatus.value = 0;
                    }
                });
        });
    }

    /**
     * 开始验证码倒计时
     */
    function startVerifyTimer() {
        verifyStatus.value = 2;
        verifyRetryTime.value = 60;
        verifyInterval.value = setInterval(() => {
            if (--verifyRetryTime.value === 0) {
                verifyStatus.value = 0;
                clearInterval(verifyInterval.value);
            }
        }, 1e3);
    }

    /**
     * 验证码校验规则
     *
     * @param {Object} rule 规则对象
     * @param {string} value 值
     * @param {Function} callback 回调函数
     * @return {Function} 回调函数
     */
    function validVerify(rule, value, callback) {
        // 如果 isSubmitValidate 为true的话，会不check验证码正确与否
        if (isSubmitValidate.value) {
            return callback();
        }

        settleFormRef.value?.validateField('phone', async valid => {
            if (!valid) {
                // 电话校验不通过则不进行异步校验
                return callback();
            }

            // 异步校验验证码
            try {
                const res = await postCheckCaptchaApi({
                    phone_num: form.phone,
                    captcha: form.captcha,
                });

                const {status, msg} = res;

                status ? callback(new Error(msg)) : callback();
            }
            catch (err) {
                callback(new Error(NET_ERROR_TIP));
            }
        });
    }

    return {
        verifyStatus,
        verifyRetryTime,
        handleVerify,
        validVerify,
    };
}
