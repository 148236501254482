import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import NotFound from '@/components/NotFound/index.vue';
export default defineComponent({
  name: 'IllegalId',
  components: {
    NotFound
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  }
});