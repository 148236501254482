import {PickPathReq} from '../interface';
import {FETCH_API} from '@/base/http';

export const PATHS = {
    /**
     * 爱采购入驻表单 - 获取企业所在地接口地址
     */
    SETTLE_AREA_API_PATH: '/getAreaNewTree',

    /**
     * 爱采购入驻表单 - 获取注册的百度账号的手机号和姓名接口地址
     */
    SETTLE_PHONE_AND_NAME_API_PATH: '/getTelAndNameAjax',

    /**
     * 爱采购入驻表单 - 根据企业名称中输入的文字获取相关企业信息接口地址
     */
    SETTLE_COMPANY_SUGGEST_LIST_API_PATH: '/getSuggestList',

    /**
     * 爱采购入驻表单 - 获取验证码地址
     */
    SETTLE_SEND_CAPTCHA_API_PATH: '/sendCaptcha',

    /**
     * 爱采购入驻表单 - 验证验证码是否正确地址
     */
    SETTLE_CHECK_CAPTCHA_API_PATH: '/checkCaptcha',

    /**
     * 爱采购入驻表单 - 提交表单地址
     */
    SETTLE_SUBMIT_API_PATH: '/submit',
} as const;


export function getSettleAreaApi() {
    return FETCH_API.get(PATHS.SETTLE_AREA_API_PATH, {
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

/**
 * 获取注册的百度账号的手机号和姓名
 */
export function getPhoneAndNameApi() {
    return FETCH_API.get(PATHS.SETTLE_PHONE_AND_NAME_API_PATH, {
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

/**
 * 根据企业名称中输入的文字获取相关企业信息
 */
export function getCompanySuggestListApi(params: PickPathReq<typeof PATHS.SETTLE_COMPANY_SUGGEST_LIST_API_PATH>) {
    return FETCH_API.get(PATHS.SETTLE_COMPANY_SUGGEST_LIST_API_PATH, {
        params,
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

/**
 * 获取验证码
 */
export function postCaptchaApi(params: PickPathReq<typeof PATHS.SETTLE_SEND_CAPTCHA_API_PATH>) {
    return FETCH_API.post(PATHS.SETTLE_SEND_CAPTCHA_API_PATH, {
        data: params,
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        isReturnResolveWhenErrorcode: true,
        isB2bPost: true,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

/**
 * 验证验证码
 */
export function postCheckCaptchaApi(params: PickPathReq<typeof PATHS.SETTLE_CHECK_CAPTCHA_API_PATH>) {
    return FETCH_API.post(PATHS.SETTLE_CHECK_CAPTCHA_API_PATH, {
        data: params,
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        silent: true,
        // 错误码时 封装的请求 api 会使用 resolve 返回结果
        isReturnResolveWhenErrorcode: true,
        isB2bPost: true,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

/**
 * 提交入驻申请表单
 */
export function postSubmitSettleFormApi(params: PickPathReq<typeof PATHS.SETTLE_SUBMIT_API_PATH>) {
    return FETCH_API.post(PATHS.SETTLE_SUBMIT_API_PATH, {
        data: params,
        baseURL: process.env.VUE_APP_B2B_OPEN_API,
        isB2bPost: true,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}
