import { defineComponent, ref, computed, onMounted, nextTick, createApp, watchEffect } from 'vue';
import xss from 'xss';
import ExtendProdItem from './ExtendProdItem.vue';
export default defineComponent({
  name: 'DetailContent',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const recommendProdList = computed(() => {
      var _props$data;
      return ((_props$data = props.data) === null || _props$data === void 0 ? void 0 : _props$data.text_product_recommend) || [];
    });
    const contentHtml = ref('');
    const isMounted = ref(false);
    onMounted(() => {
      isMounted.value = true;
    });
    /**
     * 生成包含挂载推荐产品层元素的 html
     */
    function genContentHtml(str) {
      let content = str || '';
      // xss 过滤富文本，防止 xss 攻击, <!--prod-card index=0--> 标签是需要保留的，他标识在此位置需要插入一个推荐商品
      content = xss(content, {
        allowCommentTag: true,
        onIgnoreTag: (tag, html, options) => {
          if (tag === '!--prod-card') {
            return html;
          }
        }
      });
      // 把 content 里的 '<!--prod-card index=0-->' 替换成 '<div id="extend-prod-card__0"></div>'
      const reg = /<!--prod-card index=\d+-->/g;
      content = content.replace(reg, matchStr => {
        var _indexReg$exec;
        const indexReg = /<!--prod-card index=(\d)+-->/g;
        const index = Number((_indexReg$exec = indexReg.exec(matchStr)) === null || _indexReg$exec === void 0 ? void 0 : _indexReg$exec[1]) || 0;
        // eslint-disable-next-line max-len
        const replaceStr = `<div id="extend-prod-card__${index}" data-id="${index}" class="extend-prod-card"></div>`;
        return replaceStr;
      });
      return content;
    }
    /**
     * 生成资讯内容 html
     */
    watchEffect(() => {
      var _props$data2, _recommendProdList$va, _props$data3;
      let content = ((_props$data2 = props.data) === null || _props$data2 === void 0 ? void 0 : _props$data2.content) || '';
      if (!((_recommendProdList$va = recommendProdList.value) !== null && _recommendProdList$va !== void 0 && _recommendProdList$va.length) || !content) {
        contentHtml.value = xss(content);
        return;
      }
      // productRecommendQuery 用于推荐商品的相关点击事件发生时埋点上报使用，以用来区分点击的推荐商品的推荐策略
      const productRecommendQuery = ((_props$data3 = props.data) === null || _props$data3 === void 0 ? void 0 : _props$data3.product_recommend_query) || '';
      const logInfo = {
        'product_recommend_query': productRecommendQuery
      };
      // 生成含有可以挂载推荐产品层元素的 html, 挂载元素为 '<div id="extend-prod-card__0"></div>'
      contentHtml.value = genContentHtml(content);
      // 在客户端渲染时 把推荐产品挂载到 html 中
      if (isMounted.value) {
        nextTick(() => {
          recommendProdList.value.forEach((item, index) => {
            const extendProdItemEle = document.querySelector(`#extend-prod-card__${index}`);
            if (extendProdItemEle) {
              var _props$data4;
              const extendProdItemApp = createApp(ExtendProdItem, {
                item,
                index,
                keywords: ((_props$data4 = props.data) === null || _props$data4 === void 0 ? void 0 : _props$data4.flutter) || '',
                logInfo
              });
              extendProdItemApp.mount(extendProdItemEle);
            }
          });
        });
      }
    });
    return {
      recommendProdList,
      contentHtml,
      isMounted
    };
  }
});