import {NEWS_CATEGORY_ENUM} from '@/base/constants/news';

export interface INewBase {
    id: string;
    title: string;
    // 发表时间
    issue_at: string;
    // 浏览量
    view: string;
    like: string;
}

export interface INewIntro extends INewBase {
    // 预览图url
    general_image: string;
    digest: string; // 摘要
    jump_url: string;
}

export interface IRecommendProductBase {
    id: string;
    image: string;
    name: string;
    inquiry_url?: string;
    jump_url?: string;
    tag_list?: string[];
    address?: string;
    price?: string;
    priceCurrency?: string;
    provider_name?: string;
    unitCode?: string;
    min_value?: string;
    phone400?: string;
    solution?: {
        encrSellerId: string;
        id: string;
    };
}

export interface INewsDetail extends INewBase {
    // html正文
    content: string;
    // 摘要
    digest: string;
    // 图片
    general_image: string;
    // general_type: ai 生成文章时使用的生成方式
    general_type?: number;
    source: string; // source: 亿邦动力
    author: string; // author: "亿邦动力网"
    source_link?: string;
    last_id: string;
    last_title: string;
    next_id: string;
    next_title: string;
    tag?: string[];
    description?: string;
    // 推荐产品的关键词
    ner_query?: string;
    // 推荐商品的关键词(飘红使用) 多个关键词使用英文逗号分隔, 例如: "100g,500ml"
    flutter?: string;
    product_recommend: IRecommendProductBase[];
    // 推荐产品的策略标识 用于埋点上报识别当前商品的推荐策略
    product_recommend_query?: string;
    text_product_recommend?: IRecommendProductBase[];
}

interface IGetNewsListBaseReq {
    page?: number;
    page_size?: number;
}
export interface ISearchNewsListReq extends IGetNewsListBaseReq {
    query: string;
}
export interface ILabelSearchListReq extends IGetNewsListBaseReq {
    tag?: string;
}

export interface ICategoryNewsListReq extends IGetNewsListBaseReq {
    // term: 类型 - 最新资讯/市场动态/营销干货
    term: NEWS_CATEGORY_ENUM | keyof typeof NEWS_CATEGORY_ENUM;
}

export interface INewsCommodityListReq extends IGetNewsListBaseReq {
    query?: string;
    page_id?: string;
}

export type IGetNewsListReq = ISearchNewsListReq | ICategoryNewsListReq | ILabelSearchListReq;

export interface IGetNewsListRes {
    items: INewIntro[];
    total?: number;
    page?: number;
    page_size?: number;
}

/**
 * 区分哪个 path 打开的资讯详情页
 * 1: news: '/news/detail/:id'
 * 2: contents: '/contents/detail/:id'
 */
export enum NEWS_DETAIL_PATH_ENUM {
    news = 'news',
    contents = 'contents',
}
export interface IGetNewsDetailReq {
    id: string;
    // 在页面路由 path 是 /contents/detail/:id' 时，必须要传递此参数
    path?: NEWS_DETAIL_PATH_ENUM;
}

export interface INewsCommodityItemData {
    id: string;
    image: string;
    name: string;
    jump_url: string;
    provider_name: string;
    address: string;
}

export interface IQuestionsItem {
    title: string;
    url: string;
}

export interface INewsCommodityListRes {
    items: INewsCommodityItemData[];
    page?: number;
    total?: number;
    query: string;
    seo: {
        title: string;
        keywords: string;
        description: string;
    };
    recom_list_page?: IQuestionsItem[];
}

/**
 * 询盘表单触发提交请求的页面来源标识
 * 1: newsDetail: 资讯详情页
 */
export enum INQUIRY_TYPE_ENUM {
    newsDetail = 1,
}

/**
 * 询盘表单
 */
export interface IInquiryFormReq {
    product_name: string;
    name: string;
    phone: string;
    type: INQUIRY_TYPE_ENUM;
    captcha: string;
    product_count: number;
    product_unit: string;
    quote_deadline: string;
}

/**
 * 询盘短线验证码
 */
export interface IInquiryCaptchaReq {
    phone: string;
    type: INQUIRY_TYPE_ENUM;
}

/**
 * 爱采购入驻申请form
 */
export interface ISettlleFormReq {

}