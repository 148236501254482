import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65f98ac7"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "product-info"
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  key: 0,
  class: "product-tags"
};
const _hoisted_6 = {
  key: 0,
  class: "product-address"
};
const _hoisted_7 = {
  key: 1,
  class: "product-bottom"
};
const _hoisted_8 = {
  key: 0,
  class: "product-price"
};
const _hoisted_9 = {
  key: 0,
  class: "price-sign"
};
const _hoisted_10 = {
  class: "price-count"
};
const _hoisted_11 = {
  key: 1,
  class: "product-company"
};
const _hoisted_12 = {
  key: 0,
  class: "product-inquiry"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$item, _ctx$item2, _ctx$item3, _ctx$item4, _ctx$item5, _ctx$item6, _ctx$item7, _ctx$item8;
  return _openBlock(), _createElementBlock("a", {
    href: (_ctx$item = _ctx.item) === null || _ctx$item === void 0 ? void 0 : _ctx$item.jump_url,
    target: "_blank",
    class: "product-item",
    rel: "nofollow",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleClick && _ctx.handleClick(...args), ["stop"]))
  }, [_createElementVNode("img", {
    class: "product-image",
    src: (_ctx$item2 = _ctx.item) === null || _ctx$item2 === void 0 ? void 0 : _ctx$item2.image
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "product-name",
    innerHTML: _ctx.productNameHtml
  }, null, 8, _hoisted_4), (_ctx$item3 = _ctx.item) !== null && _ctx$item3 !== void 0 && _ctx$item3.tag_list && _ctx.item.tag_list.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.tag_list, tag => {
    return _openBlock(), _createElementBlock("span", {
      key: tag,
      class: "product-tag"
    }, _toDisplayString(tag), 1);
  }), 128)), (_ctx$item4 = _ctx.item) !== null && _ctx$item4 !== void 0 && _ctx$item4.address ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.item.address), 1)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), (_ctx$item5 = _ctx.item) !== null && _ctx$item5 !== void 0 && _ctx$item5.price || (_ctx$item6 = _ctx.item) !== null && _ctx$item6 !== void 0 && _ctx$item6.provider_name ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_ctx$item7 = _ctx.item) !== null && _ctx$item7 !== void 0 && _ctx$item7.price ? (_openBlock(), _createElementBlock("span", _hoisted_8, [_ctx.item.price !== '面议' ? (_openBlock(), _createElementBlock("span", _hoisted_9, "￥")) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formatPrice), 1)])) : _createCommentVNode("", true), (_ctx$item8 = _ctx.item) !== null && _ctx$item8 !== void 0 && _ctx$item8.provider_name ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("p", null, _toDisplayString(_ctx.item.provider_name), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]), _ctx.isInquiryBtnVisible ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", {
    class: "inquiry-btn",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.handleCallPhoneOrToB2bInquiry(_ctx.item), ["prevent", "stop"]))
  }, _toDisplayString(_ctx.isMobile ? '拨打电话' : '获取底价'), 1)])) : _createCommentVNode("", true)], 8, _hoisted_1);
}